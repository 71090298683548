// Colors

// The color palette
$palette: (
	light: (
		1: #FFFFFF,
		2: #F3F5F8,
		3: #E4E8EE
	),
	dark: (
		1: #202932,
		2: #3C4A57,
		3: #96A3AE
	),
	
	primary: (
		1: #5372ff,
		2: #594BE0,
		3: #2B1DA8,
		4: #DDD9FF
 	),
	secondary: (
		1: #0088FF,
		2: #33A0FF,
		3: #006DCC,
		4: #C7E5FF
	),
	alert: (
		error: #FB6C55,
		warning: #FBCC55,
		success: #22FAD2
	)
);

// Base typography: color
$color: (
	heading:				get-color(dark, 1),
	base: 					get-color(dark, 2),
	high-contrast: 			get-color(dark, 1),
	mid-contrast: 			get-color(dark, 2),
	low-contrast: 			get-color(dark, 3),
	primary:				get-color(primary, 1),
	secondary:				get-color(secondary, 1),
	error:					get-color(alert, error),
	warning:				get-color(alert, warning),
	success:				get-color(alert, success),
	// ↓ Inverted colors
	heading-inverse:		get-color(light, 1),
	base-inverse:			get-color(dark, 3),
	high-contrast-inverse: 	get-color(light, 1),
	mid-contrast-inverse: 	get-color(light, 2),
	low-contrast-inverse: 	get-color(dark, 3)
);

// Borders and dividers: color
$border--color: (
	divider:			get-color(light, 3),
	divider-inverse:	get-color(dark, 2)
);

// Icons: fill color
$icon--color: (
	hamburger:				get-color(dark, 1),			// hamburger icon
	accordion:				get-color(primary, 2),		// accordion icon
	social: 				get-color(secondary, 1),	// social icons
	social-hover: 			get-color(secondary, 2),	// social icons (:hover)
	modal:					get-color(dark, 3),			// modal icon
	modal-hover:			get-color(dark, 2),			// modal icon (:hover)
    news-more:			    get-color(primary, 2),		// news - read more link arrow    
	// ↓ Inverted colors
	hamburger-inverse:		get-color(light, 1),		// hamburger icon
	accordion-inverse:		get-color(light, 1),		// accordion icon
	social-inverse: 		null,						// social icons
	social-hover-inverse: 	null						// social icons (:hover)
);

// Misc elements: background color
$bg--color: (
	body: 			get-color(light, 1),
	body-shadow:	0 20px 48px rgba(get-color(dark, 1), .1),	// body shadow when a boxed layout is used (set to null if don't want a shadow)
	body-outer:		get-color(light, 2),						// outer bg color when a boxed layout is used
	bg-color: 		get-color(dark, 1),							// .has-bg-color helper class
	shadow: 		0 32px 64px rgba(get-color(dark, 1), .24),	// .has-shadow helper class
	code: 			get-color(light, 2),
	code-inverse:	darken(get-color(dark, 1), 3%)
);
